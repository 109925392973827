import React from "react";
import Navbar from "./components/Navbar";
import Headline from "./components/headline";
import Author from "./components/Author";
import FAQ from "./components/FAQ";
import AddDocumentForm from "./components/Contact";
import PricingSecond from "./components/PricingSecond";

function App() {
  return (
    <div>
      <Navbar />
      <Headline />
      <Author />
      <FAQ />
      <PricingSecond />
      <AddDocumentForm />
    </div>
  );
}

export default App;
