import React from "react";


const FAQ = () => {
    return (
        <div className="w-full bg-gray-900 py-[150px] lg:py-[200px] ">
            <div className="flex items-center justify-center">
                <div className="items-start w-full lg:flex xl:flex">
                    <h1 className="text-white font-bold text-6xl px-14 pb-10">
                        Frequently Asked Questions
                    </h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-14 px-6">
                        <div>
                            <h2 className="text-white text-3xl font-bold pb-3">What exactly is it?</h2>
                            <p className="text-white text-xl">Designed to be a very lightweight library used to train and save a Convolutional Neural Network (CNN) ML model on a set of input images of your choice. You provide the image and class names and the library does everything else.</p>
                        </div>
                        <div>
                            <h2 className="text-white text-3xl font-bold pb-3">What exactly do I get?</h2>
                            <p className="text-white text-xl">Upon purchase, you will be able to download a .zip file containing the library needed and straightforwards instructions to train your model. All you have to do is put the images in the images/ folder and press run!</p>
                        </div>
                        <div>
                            <h2 className="text-white text-3xl font-bold pb-3">What do I need to use it?</h2>
                            <p className="text-white text-xl">To use this tool, you need to have (minimal) prior experience working with python code in case you'd like to tweak things. Other than that, it really is as simple as put your images in, and click run.</p>
                        </div>
                        <div>
                            <h2 className="text-white text-3xl font-bold pb-3">How often is the library updated?</h2>
                            <p className="text-white text-xl">I use this library a lot personally for training models for ios/Android apps so I'm always tinkering around. If you have an idea for a useful update, I'd love to hear it!</p>
                        </div>
                        <div>
                            <h2 className="text-white text-3xl font-bold pb-3">Are there any other costs involved?</h2>
                            <p className="text-white text-xl">Nope, once you've paid, you've got all you need.</p>
                        </div>
                        <div>
                            <h2 className="text-white text-3xl font-bold pb-3">Do you do refunds?</h2>
                            <p className="text-white text-xl">Once you have access to the library, its yours to keep for life so I cannot offer refunds. If you have a problem with the product, please email me directly and I can try to help you: cloud.point88@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ