import React from "react";
import video1 from "../assets/video/highly_compressed_vid.mp4";
import BuyButton from "./buyButton";

const Headline = () => {
    return (
        <div className="w-full bg-black lg:py-[200px] md:py-[150px] ">
            <div className="px-20 md:flex lg:flex items-center">
                <div>
                    <h1 className="text-white font-extrabold pt-6 text-6xl md:text-6xl lg:text-8xl sm:text-4xl">
                        Train your model in minutes, save hours
                    </h1>
                    <p className="text-white md:text-2xl lg:text-1xl py-6">
                        Simply click run and train an image classifier on any inputted images.
                    </p>
                    <BuyButton />

                    <div className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6" color="green">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
                        </svg>

                        <p className="text-white md:text-1xl lg:text-md py-4">
                            50% off for the first 100 customers
                        </p>
                    </div>
                </div>
                <video className="md:rotate-90 lg:rotate-90" src={video1} width="600" height="300" autoplay="autoplay" loop muted />
            </div>
            <div className="flex items-center justify-center space-x-1 pt-10">
                <p className="text-white">Featured on</p>
                <a href="https://x.com/solodevdude">
                    <img className="h-10"
                        src={require("../assets/images/x_logo.png")} alt="X logo"
                    />
                </a>
            </div>
            <div className="flex items-center justify-center space-x-2 py-3">
                <p className="text-white">Used in</p>
                <a href="https://cloud-point.co.uk">
                    <img className="h-10 rounded-md"
                        src={require("../assets/images/cp_icon.png")} alt="Cloud Point Icon"
                    />
                </a>
            </div>
        </div>
    )
}

export default Headline