import React from "react";


const Author = () => {
    return (
        <div className="w-full bg-gray-600  py-[150px] lg:py-[200px] sm:space-x-14 flex items-center justify-center">
            <div className="w-2/3 sm:flex md:flex lg:flex xl:flex gap-x-10 items-center justify-center">
                <img className="h-[250px] rounded-xl"
                    src={require("../assets/images/author.jpg")} alt="Laurence Wayne"
                />
                <div className="sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 pt-5">
                    <h2 className="text-white font-bold text-3xl">Built by Laurence</h2>
                    <p className="text-white">For the last year, I have been heavily concentrating (when my full-time job allows...) on mobile app development; specifically making apps which use ML/AI to perform some function. The project I have spent the most time on is Cloud Point which uses a CNN model to identfy which types of clouds are in an image.
                        I wasted quite a lot of time on researching which ML classifier would be best and how to actually train one (a lot of medium articles...!). Eventually though, I did it and thought I could share my process with others to save them a bunch of time.
                    </p>
                    <p className="text-yellow-400 py-2 text-3xl font-bold">I wish this had existed when I started.</p>
                </div>
            </div>
        </div>
    )
}

export default Author