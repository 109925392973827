import React from "react";


const BuyButton = () => {
    return (
        <button onClick={() => window.scrollTo({ top: 3000, behavior: "smooth" })} className="bg-yellow-600 hover:bg-yellow-400 w-[275px] rounded-md font-bold mx-auto my-6 py-3 transition duration-300">
            <div className="flex items-center justify-center">
                <img className="h-10 rounded-lg"
                    src={require("../assets/images/qc_logo.png")} alt="Quick Classifier logo"
                />
                <p className="text-black pl-3">Get Quick Classifier</p>
            </div>
        </button>
    )
}

export default BuyButton