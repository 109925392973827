// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCnYkyr95vZUcBGqorQzQq1dZEuQQ0IY3o",
  authDomain: "quick-classifier.firebaseapp.com",
  projectId: "quick-classifier",
  storageBucket: "quick-classifier.appspot.com",
  messagingSenderId: "743164883461",
  appId: "1:743164883461:web:3bbed591972fe67c31e26c",
  measurementId: "G-8S0BSQ2WQY",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(firebaseApp);

export { firebaseApp, db };
const analytics = getAnalytics(firebaseApp);